import React, { useContext, useEffect } from 'react';
import { Script } from 'gatsby';
import { library } from '@fortawesome/fontawesome-svg-core';
//duotone
import { faPhoneAlt } from '@fortawesome/pro-duotone-svg-icons/faPhoneAlt';
import { faPhoneOffice } from '@fortawesome/pro-duotone-svg-icons/faPhoneOffice';
import { faCartShopping } from '@fortawesome/pro-duotone-svg-icons/faCartShopping';
import { faQuoteRight } from '@fortawesome/pro-duotone-svg-icons/faQuoteRight';
import { faUserTie } from '@fortawesome/pro-duotone-svg-icons/faUserTie';
import { faUserHeadset } from '@fortawesome/pro-duotone-svg-icons/faUserHeadset';
import { faHandHoldingBox } from '@fortawesome/pro-duotone-svg-icons/faHandHoldingBox';
// import { faTruckContainer } from '@fortawesome/pro-duotone-svg-icons/faTruckContainer';
import { faBoxAlt } from '@fortawesome/pro-duotone-svg-icons/faBoxAlt';
// import { faBurgerSoda } from '@fortawesome/pro-duotone-svg-icons/faBurgerSoda';
import { faCannabis } from '@fortawesome/pro-duotone-svg-icons/faCannabis';
import { faTruckCouch } from '@fortawesome/pro-duotone-svg-icons/faTruckCouch';
import { faTruckClock } from '@fortawesome/pro-duotone-svg-icons/faTruckClock';
import { faAmbulance } from '@fortawesome/pro-duotone-svg-icons/faAmbulance';
// import { faDollyFlatbed } from '@fortawesome/pro-duotone-svg-icons/faDollyFlatbed';
import { faHandHoldingSeedling } from '@fortawesome/pro-duotone-svg-icons/faHandHoldingSeedling';
import { faHandHoldingHeart } from '@fortawesome/pro-duotone-svg-icons/faHandHoldingHeart';
// import { faConciergeBell } from '@fortawesome/pro-duotone-svg-icons/faConciergeBell';
import { faEnvelopeOpenDollar } from '@fortawesome/pro-duotone-svg-icons/faEnvelopeOpenDollar';
import { faStar } from '@fortawesome/pro-duotone-svg-icons/faStar';
// import { faDebug } from '@fortawesome/pro-duotone-svg-icons/faDebug';
// import { faSprinkler } from '@fortawesome/pro-duotone-svg-icons/faSprinkler';
// import { faTrees } from '@fortawesome/pro-duotone-svg-icons/faTrees';
// import { faVacuum } from '@fortawesome/pro-duotone-svg-icons/faVacuum';
// import { faToilet } from '@fortawesome/pro-duotone-svg-icons/faToilet';
// import { faPlug } from '@fortawesome/pro-duotone-svg-icons/faPlug';
// import { faVacuumRobot } from '@fortawesome/pro-duotone-svg-icons/faVacuumRobot';
// import { faSortCircleUp } from '@fortawesome/pro-duotone-svg-icons/faSortCircleUp';
// import { faSwimmingPool } from '@fortawesome/pro-duotone-svg-icons/faSwimmingPool';
// import { faOven } from '@fortawesome/pro-duotone-svg-icons/faOven';
// import { faAirConditioner } from '@fortawesome/pro-duotone-svg-icons/faAirConditioner';
import { faFish } from '@fortawesome/pro-duotone-svg-icons/faFish';
// import { faTruckLoading } from '@fortawesome/pro-duotone-svg-icons/faTruckLoading';
// import { faShippingTimed } from '@fortawesome/pro-duotone-svg-icons/faShippingTimed';
import { faAppleCrate } from '@fortawesome/pro-duotone-svg-icons/faAppleCrate';
// import { faBriefcase } from '@fortawesome/pro-duotone-svg-icons/faBriefcase';
import { faBlog } from '@fortawesome/pro-duotone-svg-icons/faBlog';
// import { faCogs } from '@fortawesome/pro-duotone-svg-icons/faCogs';
import { faCalendarCheck } from '@fortawesome/pro-duotone-svg-icons/faCalendarCheck';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons/faUsers';
// import { faNewspaper } from '@fortawesome/pro-duotone-svg-icons/faNewspaper';
import { faRss } from '@fortawesome/pro-duotone-svg-icons/faRss';
import { faEnvelopeOpenText } from '@fortawesome/pro-duotone-svg-icons/faEnvelopeOpenText';
import { faListAlt } from '@fortawesome/pro-duotone-svg-icons/faListAlt';
import { faIndustry } from '@fortawesome/pro-duotone-svg-icons/faIndustry';
import { faCubes } from '@fortawesome/pro-duotone-svg-icons/faCubes';
import { faComments } from '@fortawesome/pro-duotone-svg-icons/faComments';
import { faBuilding } from '@fortawesome/pro-duotone-svg-icons/faBuilding';
import { faHeadset } from '@fortawesome/pro-duotone-svg-icons/faHeadset';
import { faHandshake } from '@fortawesome/pro-duotone-svg-icons/faHandshake';
import { faRoute } from '@fortawesome/pro-duotone-svg-icons/faRoute';
import { faClipboardListCheck } from '@fortawesome/pro-duotone-svg-icons/faClipboardListCheck';
import { faMobileAlt } from '@fortawesome/pro-duotone-svg-icons/faMobileAlt';
import { faFileInvoiceDollar } from '@fortawesome/pro-duotone-svg-icons/faFileInvoiceDollar';
import { faFishFins } from '@fortawesome/pro-duotone-svg-icons/faFishFins';
import { faMobileButton } from '@fortawesome/pro-duotone-svg-icons/faMobileButton';
import { faListCheck } from '@fortawesome/pro-duotone-svg-icons/faListCheck';
import { faWineBottle } from '@fortawesome/pro-duotone-svg-icons/faWineBottle';
import { faPersonDolly } from '@fortawesome/pro-duotone-svg-icons/faPersonDolly';
import { faTrashCanSlash } from '@fortawesome/pro-duotone-svg-icons/faTrashCanSlash';
// import { faWashingMachine } from '@fortawesome/pro-duotone-svg-icons/faWashingMachine';
// import { faRug } from '@fortawesome/pro-duotone-svg-icons/faRug';
import { faPlateUtensils } from '@fortawesome/pro-duotone-svg-icons/faPlateUtensils';
// import { faChimney } from '@fortawesome/pro-duotone-svg-icons/faChimney';
// import { faUserHelmetSafety } from '@fortawesome/pro-duotone-svg-icons/faUserHelmetSafety';
import { faTruckFast } from '@fortawesome/pro-duotone-svg-icons/faTruckFast';
// import { faShredder } from '@fortawesome/pro-duotone-svg-icons/faShredder';
// import { faPlugCircleBolt } from '@fortawesome/pro-duotone-svg-icons/faPlugCircleBolt';
// import { faFireExtinguisher } from '@fortawesome/pro-duotone-svg-icons/faFireExtinguisher';
import { faGasPump } from '@fortawesome/pro-duotone-svg-icons/faGasPump';
// import { faGarage } from '@fortawesome/pro-duotone-svg-icons/faGarage';
// import { faToolbox } from '@fortawesome/pro-duotone-svg-icons/faToolbox';
// import { faHouseTree } from '@fortawesome/pro-duotone-svg-icons/faHouseTree';
// import { faLockKeyhole } from '@fortawesome/pro-duotone-svg-icons/faLockKeyhole';
import { faCartFlatbedBoxes } from '@fortawesome/pro-duotone-svg-icons/faCartFlatbedBoxes';
// import { faBroom } from '@fortawesome/pro-duotone-svg-icons/faBroom';
// import { faBiohazard } from '@fortawesome/pro-duotone-svg-icons/faBiohazard';
import { faScrewdriverWrench } from '@fortawesome/pro-duotone-svg-icons/faScrewdriverWrench';
// import { faBug } from '@fortawesome/pro-duotone-svg-icons/faBug';
// import { faPaw } from '@fortawesome/pro-duotone-svg-icons/faPaw';
// import { faPipeValve } from '@fortawesome/pro-duotone-svg-icons/faPipeValve';
// import { faPoop } from '@fortawesome/pro-duotone-svg-icons/faPoop';
// import { faHose } from '@fortawesome/pro-duotone-svg-icons/faHose';
import { faHouse } from '@fortawesome/pro-duotone-svg-icons/faHouse';
// import { faTruckPlow } from '@fortawesome/pro-duotone-svg-icons/faTruckPlow';
import { faTreeLarge } from '@fortawesome/pro-duotone-svg-icons/faTreeLarge';
// import { faWindowFrame } from '@fortawesome/pro-duotone-svg-icons/faWindowFrame';
// import { faCameraCctv } from '@fortawesome/pro-duotone-svg-icons/faCameraCctv';
import { faCalendarClock } from '@fortawesome/pro-duotone-svg-icons/faCalendarClock';
import { faCommentSms } from '@fortawesome/pro-duotone-svg-icons/faCommentSms';
import { faMapLocationDot } from '@fortawesome/pro-duotone-svg-icons/faMapLocationDot';
import { faLocationCheck } from '@fortawesome/pro-duotone-svg-icons/faLocationCheck';
import { faSeedling } from '@fortawesome/pro-duotone-svg-icons/faSeedling';
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';
import { faCirclePhone } from '@fortawesome/pro-duotone-svg-icons/faCirclePhone';
import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons/faEnvelope';
// import { faShieldKeyhole } from '@fortawesome/pro-duotone-svg-icons/faShieldKeyhole';
import { faBellOn } from '@fortawesome/pro-duotone-svg-icons/faBellOn';
// import { faMagnifyingGlass } from '@fortawesome/pro-duotone-svg-icons/faMagnifyingGlass';
// import { faArrowsRotate } from '@fortawesome/pro-duotone-svg-icons/faArrowsRotate';
import { faCloudCheck } from '@fortawesome/pro-duotone-svg-icons/faCloudCheck';
import { faCloudUpload } from '@fortawesome/pro-duotone-svg-icons/faCloudUpload';
import { faGlobe } from '@fortawesome/pro-duotone-svg-icons/faGlobe';
import { faCircleExclamation } from '@fortawesome/pro-duotone-svg-icons/faCircleExclamation';
import { faUserGroup } from '@fortawesome/pro-duotone-svg-icons/faUserGroup';
// import { faBuildingShield } from '@fortawesome/pro-duotone-svg-icons/faBuildingShield';
// import { faTimer } from '@fortawesome/pro-duotone-svg-icons/faTimer';
// import { faVault } from '@fortawesome/pro-duotone-svg-icons/faVault';
// import { faCode } from '@fortawesome/pro-duotone-svg-icons/faCode';
// import { faShieldCheck } from '@fortawesome/pro-duotone-svg-icons/faShieldCheck';
// import { faUserShield } from '@fortawesome/pro-duotone-svg-icons/faUserShield';
// import { faUserLock } from '@fortawesome/pro-duotone-svg-icons/faUserLock';
// import { faShieldHalved } from '@fortawesome/pro-duotone-svg-icons/faShieldHalved';
// import { faMedal } from '@fortawesome/pro-duotone-svg-icons/faMedal';
// import { faSearch } from '@fortawesome/pro-duotone-svg-icons/faSearch';
import { faHandsHoldingDollar } from '@fortawesome/pro-duotone-svg-icons/faHandsHoldingDollar';
import { faCircleNodes } from '@fortawesome/pro-duotone-svg-icons/faCircleNodes';
import { faCalendarDays } from '@fortawesome/pro-duotone-svg-icons/faCalendarDays';
import { faCircleDollar } from '@fortawesome/pro-duotone-svg-icons/faCircleDollar';
import { faChartMixed } from '@fortawesome/pro-duotone-svg-icons/faChartMixed';
import { faChartNetwork } from '@fortawesome/pro-duotone-svg-icons/faChartNetwork';
// import { faBuildingFlag } from '@fortawesome/pro-duotone-svg-icons/faBuildingFlag';
// import { faFileImage } from '@fortawesome/pro-duotone-svg-icons/faFileImage';
import { faHandHoldingDollar } from '@fortawesome/pro-duotone-svg-icons/faHandHoldingDollar';
import { faCreditCard } from '@fortawesome/pro-duotone-svg-icons/faCreditCard';
import { faLaptopMobile } from '@fortawesome/pro-duotone-svg-icons/faLaptopMobile';
import { faChartUser } from '@fortawesome/pro-duotone-svg-icons/faChartUser';
import { faLightbulbOn } from '@fortawesome/pro-duotone-svg-icons/faLightbulbOn';
import { faMobile } from '@fortawesome/pro-duotone-svg-icons/faMobile';
import { faStopwatch } from '@fortawesome/pro-duotone-svg-icons/faStopwatch';
import { faBoltLightning } from '@fortawesome/pro-duotone-svg-icons/faBoltLightning';
import { faCalendarPlus } from '@fortawesome/pro-duotone-svg-icons/faCalendarPlus';
import { faCalendarLinesPen } from '@fortawesome/pro-duotone-svg-icons/faCalendarLinesPen';
import { faGaugeMax } from '@fortawesome/pro-duotone-svg-icons/faGaugeMax';
import { faEye } from '@fortawesome/pro-duotone-svg-icons/faEye';
import { faLink } from '@fortawesome/pro-duotone-svg-icons/faLink';
import { faCircleBolt } from '@fortawesome/pro-duotone-svg-icons/faCircleBolt';
import { faUserPlus } from '@fortawesome/pro-duotone-svg-icons/faUserPlus';
import { faBrowser } from '@fortawesome/pro-duotone-svg-icons/faBrowser';
import { faMagnifyingGlassChart } from '@fortawesome/pro-duotone-svg-icons/faMagnifyingGlassChart';
import { faHandshakeSimple } from '@fortawesome/pro-duotone-svg-icons/faHandshakeSimple';
import { faGear } from '@fortawesome/pro-duotone-svg-icons/faGear';
import { faChartLineUp } from '@fortawesome/pro-duotone-svg-icons/faChartLineUp';
import { faCirclePlus } from '@fortawesome/pro-duotone-svg-icons/faCirclePlus';
import { faUserGear } from '@fortawesome/pro-duotone-svg-icons/faUserGear';
import { faFileInvoice } from '@fortawesome/pro-duotone-svg-icons/faFileInvoice';
import { faSquareDollar } from '@fortawesome/pro-duotone-svg-icons/faSquareDollar';
import { faMegaphone } from '@fortawesome/pro-duotone-svg-icons/faMegaphone';
import { faSquareRss } from '@fortawesome/pro-duotone-svg-icons/faSquareRss';
import { faFileChartColumn } from '@fortawesome/pro-duotone-svg-icons/faFileChartColumn';
import { faFileUser } from '@fortawesome/pro-duotone-svg-icons/faFileUser';
import { faBookOpenCover } from '@fortawesome/pro-duotone-svg-icons/faBookOpenCover';
import { faCalculatorSimple } from '@fortawesome/pro-duotone-svg-icons/faCalculatorSimple';
import { faCircleQuestion } from '@fortawesome/pro-duotone-svg-icons/faCircleQuestion';
import { faSquareStar } from '@fortawesome/pro-duotone-svg-icons/faSquareStar';
import { faChartTreeMap } from '@fortawesome/pro-duotone-svg-icons/faChartTreeMap';
import { faCircleUser } from '@fortawesome/pro-duotone-svg-icons/faCircleUser';
import { faThumbsUp } from '@fortawesome/pro-duotone-svg-icons/faThumbsUp';
import { faGaugeHigh } from '@fortawesome/pro-duotone-svg-icons/faGaugeHigh';
import { faChartColumn } from '@fortawesome/pro-duotone-svg-icons/faChartColumn';
import { faGraduationCap } from '@fortawesome/pro-duotone-svg-icons/faGraduationCap';
import { faCalendarDay } from '@fortawesome/pro-duotone-svg-icons/faCalendarDay';
import { faBoxCheck } from '@fortawesome/pro-duotone-svg-icons/faBoxCheck';
import { faBellExclamation } from '@fortawesome/pro-duotone-svg-icons/faBellExclamation';
import { faCircleInfo } from '@fortawesome/pro-duotone-svg-icons/faCircleInfo';
import { faTruck } from '@fortawesome/pro-duotone-svg-icons/faTruck';
import { faTags } from '@fortawesome/pro-duotone-svg-icons/faTags';
import { faChalkboardUser } from '@fortawesome/pro-duotone-svg-icons/faChalkboardUser';
import { faTruckBolt } from '@fortawesome/pro-duotone-svg-icons/faTruckBolt';
import { faNetworkWired } from '@fortawesome/pro-duotone-svg-icons/faNetworkWired';
import { faBoxCircleCheck } from '@fortawesome/pro-duotone-svg-icons/faBoxCircleCheck';
import { faVoicemail } from '@fortawesome/pro-duotone-svg-icons/faVoicemail';
import { faCar } from '@fortawesome/pro-duotone-svg-icons/faCar';
import { faCarWrench } from '@fortawesome/pro-duotone-svg-icons/faCarWrench';
import { faIdCard } from '@fortawesome/pro-duotone-svg-icons/faIdCard';
import { faArrowPointer } from '@fortawesome/pro-duotone-svg-icons/faArrowPointer';
import { faUpload } from '@fortawesome/pro-duotone-svg-icons/faUpload';
import { faTruckMoving } from '@fortawesome/pro-duotone-svg-icons/faTruckMoving';
import { faFileSpreadsheet } from '@fortawesome/pro-duotone-svg-icons/faFileSpreadsheet';
import { faChartLineDown } from '@fortawesome/pro-duotone-svg-icons/faChartLineDown';
import { faBox } from '@fortawesome/pro-duotone-svg-icons/faBox';
import { faTruckFront } from '@fortawesome/pro-duotone-svg-icons/faTruckFront';
import { faWebhook } from '@fortawesome/pro-duotone-svg-icons/faWebhook';
import { faToiletPortable } from '@fortawesome/pro-duotone-svg-icons/faToiletPortable';
import { faSliders } from '@fortawesome/pro-duotone-svg-icons/faSliders';
import { faTrashCan } from '@fortawesome/pro-duotone-svg-icons/faTrashCan';
import { faPrescriptionBottlePill } from '@fortawesome/pro-duotone-svg-icons/faPrescriptionBottlePill';
import { faWashingMachine } from '@fortawesome/pro-duotone-svg-icons/faWashingMachine';
import { faVial } from '@fortawesome/pro-duotone-svg-icons/faVial';

//brand
import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons/faSquareFacebook';
import { faSquareXTwitter } from '@fortawesome/free-brands-svg-icons/faSquareXTwitter';
import { faSquareInstagram } from '@fortawesome/free-brands-svg-icons/faSquareInstagram';
import { faSquareYoutube } from '@fortawesome/free-brands-svg-icons/faSquareYoutube';
//import { faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons/faSquareWhatsapp';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';

//solid
import { faEnvelope as farEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';

import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import theme from '../../theme';

import { Nav } from './Nav';
import { Footer } from './Footer';
import FormContext, { FormProvider } from '../../context/FormContext';

import 'typeface-poppins';
import 'typeface-open-sans';
import 'typeface-roboto';

library.add(
	faPhoneOffice,
	// faFacebook,
	//faFacebookSquare,
	faSquareFacebook,
	// faTwitter,
	//faTwitterSquare,
	faSquareXTwitter,
	//faInstagramSquare,
	faSquareInstagram,
	//faYoutubeSquare,
	faSquareYoutube,
	faLinkedin,
	faLinkedinIn,
	//faWhatsappSquare,
	//faSquareWhatsapp,
	faPhoneAlt,
	faArrowLeft,
	faArrowRight,
	faCartShopping,
	faQuoteRight,
	faHandHoldingSeedling,
	faHandHoldingHeart,
	// faConciergeBell,
	faEnvelopeOpenDollar,
	faStar,
	faUserTie,
	faUserHeadset,
	faHandHoldingBox,
	// faTruckContainer,
	faBoxAlt,
	// faBurgerSoda,
	faCannabis,
	faTruckCouch,
	faTruckClock,
	faAmbulance,
	// faDollyFlatbed,
	// faDebug,
	// faSprinkler,
	// faTrees,
	// faVacuum,
	// faToilet,
	// faPlug,
	// faVacuumRobot,
	// faSortCircleUp,
	// faSwimmingPool,
	faComments,
	// faOven,
	// faAirConditioner,
	faFish,
	// faTruckLoading,
	// faShippingTimed,
	faAppleCrate,
	// faBriefcase,
	faBlog,
	// faCogs,
	faCalendarCheck,
	faUsers,
	// faNewspaper,
	faRss,
	faEnvelopeOpenText,
	faListAlt,
	faIndustry,
	faCubes,
	faBuilding,
	faLink,
	faHeadset,
	faHandshake,
	faRoute,
	faClipboardListCheck,
	faMobileAlt,
	faFileInvoiceDollar,
	faFishFins,
	faMobileButton,
	faListCheck,
	faWineBottle,
	faPersonDolly,
	faTrashCanSlash,
	// faWashingMachine,
	// faRug,
	faPlateUtensils,
	// faChimney,
	// faUserHelmetSafety,
	faTruckFast,
	// faShredder,
	// faPlugCircleBolt,
	// faFireExtinguisher,
	faGasPump,
	// faGarage,
	// faToolbox,
	// faHouseTree,
	// faLockKeyhole,
	faCartFlatbedBoxes,
	// faBroom,
	// faBiohazard,
	faScrewdriverWrench,
	// faBug,
	// faPaw,
	// faPipeValve,
	// faPoop,
	// faHose,
	faHouse,
	// faTruckPlow,
	faTreeLarge,
	// faWindowFrame,
	// faCameraCctv,
	faCalendarClock,
	faCommentSms,
	faMapLocationDot,
	faLocationCheck,
	faSeedling,
	faUser,
	faCirclePhone,
	faEnvelope,
	// faShieldKeyhole,
	faBellOn,
	// faMagnifyingGlass,
	// faArrowsRotate,
	faCloudCheck,
	faCloudUpload,
	faGlobe,
	faCircleExclamation,
	faUserGroup,
	// faBuildingShield,
	// faTimer,
	// faVault,
	// faCode,
	// faShieldCheck,
	// faUserShield,
	// faUserLock,
	// faShieldHalved,
	farEnvelope,
	faXmark,
	// faMedal,
	faCheck,
	// faSearch,
	faCircleCheck,
	faHandsHoldingDollar,
	faCircleNodes,
	faCalendarDays,
	faCircleDollar,
	faChartMixed,
	// faBuildingFlag,
	// faFileImage,
	faHandHoldingDollar,
	faCreditCard,
	faLaptopMobile,
	faChartUser,
	faLightbulbOn,
	faMobile,
	faStopwatch,
	faBoltLightning,
	faCalendarPlus,
	faCalendarLinesPen,
	faGaugeMax,
	faEye,
	faCircleBolt,
	faUserPlus,
	faBrowser,
	faMagnifyingGlassChart,
	faHandshakeSimple,
	faGear,
	faChartLineUp,
	faCirclePlus,
	faUserGear,
	faFileInvoice,
	faSquareDollar,
	faMegaphone,
	faSquareRss,
	faFileChartColumn,
	faFileUser,
	faBookOpenCover,
	faCalculatorSimple,
	faCircleQuestion,
	faSquareStar,
	faChartTreeMap,
	faCircleUser,
	faThumbsUp,
	faGaugeHigh,
	faChartColumn,
	faGraduationCap,
	faCalendarDay,
	faBoxCheck,
	faBellExclamation,
	faCircleInfo,
	faTruck,
	faTags,
	faChalkboardUser,
	faTruckBolt,
	faNetworkWired,
	faBoxCircleCheck,
	faVoicemail,
	faCar,
	faCarWrench,
	faIdCard,
	faArrowPointer,
	faUpload,
	faPlay,
	faTruckMoving,
	faFileSpreadsheet,
	faChartLineDown,
	faBox,
	faTruckFront,
	faChartNetwork,
	faWebhook,
	faToiletPortable,
	faSliders,
	faTrashCan,
	faPrescriptionBottlePill,
	faWashingMachine,
	faVial
);

export const Layout = ({ children }) => {
	const { setIsLoaded } = useContext(FormContext);

	return (
		<MuiThemeProvider theme={theme}>
			{/* <Script
				src='//app-sj02.marketo.com/js/forms2/js/forms2.min.js'
				strategy='idle'
				onLoad={() => setIsLoaded(true)}
			/> */}
			{/* <Script src='https://agency-workwave01.s3.amazonaws.com/marketo-forms-test-no-babel-polyfill.min.js' /> */}
			<CssBaseline />
			<Nav />
			{children}
			<Footer />
		</MuiThemeProvider>
	);
};
